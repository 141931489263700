<template>
  <el-dialog class="editDialog" :append-to-body="true" width="600px" :visible.sync="showEditDialog"
    :close-on-click-modal="false" :destroy-on-close="true" @close="$emit('update:isShow', false)" :show-close="true">
    <div slot="title" class="title">{{ options.course_cat_id ? '编辑' : '新增' }}课程分类</div>
    <div class="content" v-loading="loading">
      <el-form label-width="7em" :model="form" :rules="rules" ref="form">

        <el-form-item label="父级分类" prop="parent_id" ref="parent_id">
          <Treeselect :normalizer="normalizer" @input="changeParentId" placeholder="请选择父级分类" v-model="form.parent_id"
            :options="treeselectOptions" />
        </el-form-item>

        <el-form-item :label="'分类名称'" prop="cat_name">
          <el-input v-model="form.cat_name" :placeholder="`请输入分类名称`"></el-input>
        </el-form-item>

        <el-form-item label="图标" prop="category_pic">
          <el-autocomplete popperClass="autocompleteIcon" v-model="form.category_pic"
            :fetch-suggestions="regularQuerySearch" placeholder="请输入图标" @select="regularHandleSelect">
            <div slot-scope="{item}" :class="`iconfont icon${item['font_class']}`"></div>
          </el-autocomplete>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
        </el-form-item>

        <el-form-item label="是否启用" prop="status">
          <el-radio-group v-model="form.status" size="medium">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>

  </el-dialog>

</template>
<script>
import { initTreeData } from '@/util/common'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import iconJson from '@/styles/icon/iconfont.json'

export default {
  components: {
    Treeselect: () => import('@riophae/vue-treeselect'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      listData: [],
      treeselectOptions: [],
      rules: {
        parent_id: [
          {
            required: true,
            message: '请选择父级分类',
            trigger: ['blur', 'change'],
          },
        ],
        cat_name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.$nextTick(() => {
          newVal && this.$refs.form && this.$refs.form.clearValidate()
        })
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal)
      },
    },
  },
  created() {
    this.$api.course
      .course_cat({
        method: 'get.course_cat.list',
        course_cat_id: this.options.course_cat_id,
        module: 'admin',
      })
      .then((res) => {
        this.listData = res.data.items
        const treeData = initTreeData(
          res.data.items,
          'parent_id',
          'course_cat_id'
        )
        this.treeselectOptions = [
          {
            course_cat_id: 0,
            cat_name: '顶级课程分类',
            children: treeData,
          },
        ]
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    init(options) {
      if (options.course_cat_id) {
        //编辑进来的会有id需要
        this.loading = true

        this.$api.course
          .course_cat({
            module: 'admin',
            course_cat_id: options.course_cat_id,
            method: 'get.course_cat.item',
          })
          .then((res) => {
            this.form = res.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.form = {
          parent_id: options.parent_id,
          status: 1,
        }
        this.loading = false
      }
    },
    regularQuerySearch(queryString, cb) {
      var restaurants = iconJson.glyphs
      var results = queryString
        ? restaurants.filter(this.regularCreateFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    regularCreateFilter(queryString) {
      return (restaurant) => {
        return (
          `${iconJson['css_prefix_text']}${restaurant['font_class']}`
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    regularHandleSelect(item) {
      this.form.category_pic = `${iconJson['css_prefix_text']}${item['font_class']}`
    },
    normalizer(node) {
      return {
        id: node.course_cat_id,
        label: node.cat_name,
        children: node.children,
      }
    },
    changeParentId() {
      this.$refs.form.validateField('parentId')
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.course
            .course_cat(
              this.form.combination({
                module: 'admin',
                method: this.form.course_cat_id
                  ? 'set.course_cat.item'
                  : 'add.course_cat.item',
              })
            )
            .then((res) => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch((err) => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
