<template>
    <div class="additional" :class="{ show: options.user_id }"  v-loading="loading">
        <div class="additional-title">{{  options.nickname  }}的收货地址</div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" height="string" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
            <el-table-column align="center" prop="consignee" label="姓名"></el-table-column>
            <el-table-column align="center" prop="mobile" label="手机号"></el-table-column>
            <el-table-column align="center" prop="mobile" label="地区">
                <template slot-scope="scope">
                    {{  scope.row.district | cascader(areaData,'region_id','region_name')  }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="详细地址"></el-table-column>
            <el-table-column align="center" prop="is_default" label="是否默认">
                <template slot-scope="scope">
                    {{  scope.row.is_default==1?'是':'否'  }}
                </template>
            </el-table-column>
            <el-table-column label="操作"  align="center" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                    <el-button type="text" size="small" v-if="scope.row.is_default!=1" @click="setUpDefault(scope)">设为默认</el-button>
                </template>
            </el-table-column>
        </el-table>
        <AddressEdit :isShow.sync="showEditDialog" :options="activeData"></AddressEdit>

    </div>
</template>
<script>


import {initTreeData} from "@/util/common";

export default {
    components: {
        AddressEdit: () => import("./AddressEdit.vue"),
    },
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data(){
        return {
            tableData:[],
            areaData:[],
            loading:true,
            activeData: {},
            showEditDialog: false,
        };
    },
    watch:{
        options: {
            immediate: true, //初始化立即执行
            deep: true, //对象深度监测
            handler: function (newVal, oldVal) {
                if(newVal.user_id){
                    this.init();
                }
            },
        },
    },
    provide () {
        return {
            refresh:this.refresh,
        }
    },
    created(){
        this.$api.dict.area().then(res => {
            if(res.data&&Array.isArray(res.data)){
                this.areaData = res.data
            };
        }).catch(err => {
            console.log(err);
        });
    },
    methods:{
        refresh(){
            this.showEditDialog = false;
            this.init();
        },
        edit(scope){
            this.activeData = scope.row;
            this.showEditDialog = true
        },
        setUpDefault(scope){
            this.loading = true;
            this.$api.user.user_address({
                method:'set.user.address.default',
                user_address_id:scope.row.user_address_id,
            }).then(res => {
                this.init();
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        init(){
            this.loading = true;
            this.$api.user.user_address({
                method:'get.user.address.list',
                client_id:this.options.user_id,
            }).then(res => {
                if(res.data&&Array.isArray(res.data)){
                    this.tableData = res.data
                };
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
